<template>
  <div v-if="option">
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="'Warnings'"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
      :drawerSize="drawerSize"
    >
      <template v-slot:content>
        <Warnings v-if="showWarningsSummary" :warnings="warnings" />
      </template>
    </ui-component-drawer>

    <transition name="fade" mode="out-in">
      <div v-if="isLoading" key="loading" class="has-padding has-text-centered">
        <ui-loader />
      </div>

      <div>
        <nav class="navbar">
          <a @click="$router.go(-1)" class="navbar-item">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </span>
            <span>Back</span>
          </a>
        </nav>

        <section class="section">
          <div class="container">
            <div class="columns">
              <div class="column">
                <div class="mb-3">
                  <div class="subtitle">
                    <a @click="setShowWarningSummary()"
                      >{{ warnings.length }} warning(s)</a
                    >
                  </div>
                </div>

                <OptionBase class="mb-5" />

                <OptionEditSettings v-if="locationId === 0" />
                <OptionLocationSettings v-if="locationId > 0" />
              </div>
              <div class="column">
                <OptionImage />
              </div>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="container">
            <div class="columns">
              <div class="column">
                <OptionChannels />
              </div>
              <div class="column">
                <OptionMeetingtypes />
              </div>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="container">
            <OptionPrices />
          </div>
        </section>

        <section class="section">
          <div class="container">
            <OptionText
              :descriptions="option.Texts"
              :locationId="locationId"
              @editOptionText="setShowEditOptionText"
            />
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import locationProvider from '@/providers/location'
import optionProvider from '@/providers/option'

export default {
  components: {
    OptionBase: () => import('@/components/Options/OptionBase'),
    OptionChannels: () => import('@/components/Options/OptionChannels'),
    OptionImage: () => import('@/components/Options/OptionImage'),
    OptionMeetingtypes: () => import('@/components/Options/OptionMeetingtypes'),
    OptionPrices: () => import('@/components/Options/OptionPrices'),
    OptionLocationSettings: () =>
      import('@/components/Options/OptionLocationSettings'),
    OptionText: () => import('@/components/Options/OptionText'),
    OptionEditSettings: () => import('@/components/Options/OptionEditSettings'),

    Warnings: () => import('@/components/UI/Warnings'),
  },

  data() {
    return {
      categories: [],
      exportgroups: [],
      isLoading: false,
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      locationMeetingtypes: [],
      optionId: Number(this.$route.params.optionId),
      showModal: false,

      showSideDrawer: false,
      showWarningsSummary: false,
      drawerSize: 'normal',
      warnings: [],
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),
  },

  created() {
    //if (!this.option) {
    this.getOptionData()

    //}

    if (this.locationId > 0) {
      this.checkOptionData()
      this.getLocationExportgroups()
    }
  },

  beforeDestroy() {
    this.setOption(null)
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    getOptionData() {
      let self = this

      optionProvider.methods
        .getOptionById(self.optionId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.setOption(response.data)
          }
        })
        .catch((err) => {})
    },

    checkOptionData() {
      let self = this

      optionProvider.methods
        .checkOption(self.channelId, self.locationId, self.optionId)
        .then((response) => {
          if (response.status === 200) {
            self.warnings = response.data
          }
        })
        .catch((err) => {})
    },

    getLocationExportgroups() {
      locationProvider.methods
        .getLocationExportgroups(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.exportgroups = response.data
          }
        })
        .catch((err) => {})
    },

    getLocationMeetingtypes() {
      let locationState = this.$store.getters['locationStore/getLocationState']
      if (locationState) {
        this.locationMeetingtypes = locationState.Meetingtypes
      }
    },

    getExportgroupName(exportgroupId) {
      let name = ''
      let exportgroupIndex = this.exportgroups.findIndex(
        (e) => e.Id === exportgroupId
      )

      if (exportgroupIndex > -1) {
        name = this.exportgroups[exportgroupIndex].Name
      }
      return name
    },

    setShowEditOptionText() {},

    setShowWarningSummary() {
      this.showSideDrawer = true
      this.showWarningsSummary = true
    },

    hideDrawer() {
      this.showSideDrawer = false
    },
  },
}
</script>

<style></style>
